import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ActiveDirectoryUserQueryComponent } from "./ActiveDirectoryUserQueryComponent";

export class ActiveDirectoryUserRouteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      primaryTableData: [],
      paintTable: false,
      isLoadingData: false,
      lastUpdatedDate: null,
      mailboxLastUpdatedDate: null
    }

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onLookupClick = this.onLookupClick.bind(this);
    this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

    props.onBreadCrumbStateChanged({
      pageTitle: this.pageTitle,
      override: true,
      link: {
        name: this.pageTitle,
        action: "add"
      }
    });

    console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
    console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
  }

  componentDidMount() {
    try {
      console.devLog("ActiveDirectoryUserRouteComponent - componentDidMount");
      this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ActiveDirectoryUser", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      this.getControllerPolicy();
      this.onSearchClick();
      this.getLastUpdatedDate();
      this.getMailboxLastUpdatedDate();
      CommonService.clientAzureStorageLog("ActiveDirectoryUserRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  pageTitle = "Active Directory Users";

  getControllerPolicy() {
    try {
      this.props.applicationInsights.trackTrace({ message: "/ActiveDirectoryUserRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/ActiveDirectoryUser/GetControllerPolicy")
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          console.devLog("ACL: " + parsedResponse.acl.join(), "ActiveDirectoryUserRouteComponent", "getControllerPolicy");
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ActiveDirectoryUser/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  getLastUpdatedDate() {
    try {
      this.props.applicationInsights.trackTrace({ message: "/ActiveDirectoryUserRouteComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/ActiveDirectoryUser/GetLastUpdated")
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          let lastUpdatedDate = "";
          if (CommonService.isDateValid(parsedResponse))
            lastUpdatedDate = moment.utc(parsedResponse);
          this.setState({ lastUpdatedDate: lastUpdatedDate });
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ActiveDirectoryUser/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  getMailboxLastUpdatedDate() {
    try {
      this.props.applicationInsights.trackTrace({ message: "/ActiveDirectoryUserRouteComponent/getMailboxLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/ActiveDirectoryUser/GetMailboxLastUpdated")
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          let lastUpdatedDate = "";
          if (CommonService.isDateValid(parsedResponse))
            lastUpdatedDate = moment.utc(parsedResponse);
          this.setState({ mailboxLastUpdatedDate: lastUpdatedDate });
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ActiveDirectoryUser/getMailboxLastUpdatedDate", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onSearchClick() {
    try {
      this.setState({ isLoadingData: true });
      this.props.applicationInsights.trackTrace({ message: "/ActiveDirectoryUserRouteComponent/onSearchClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/ActiveDirectoryUser/GetUsers")
        .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
        .then(parsedResponse => {
          parsedResponse.forEach(responseItem => {
            responseItem.otherMailsTblCol = responseItem.otherMails.join(", ");
            responseItem.proxyAddressesTblCol = responseItem.proxyAddresses.join(", ");
            responseItem.createdDate = responseItem.createdDate === "" ? null : responseItem.createdDate;
            responseItem.hireDate = responseItem.hireDate === "" ? null : responseItem.hireDate;
            responseItem.termDate = responseItem.termDate === "" ? null : responseItem.termDate;
            responseItem.paylocityStatus_cellCssName += responseItem.paylocityStatus === "T" ? " dgdg-activedirectory-user-cell-font" : " dgdg-activedirectory-user-palocity-color";
            responseItem.accountEnabled_cellCssName += responseItem.accountEnabled === false ? " dgdg-activedirectory-user-cell-font" : " dgdg-activedirectory-user-activedirectory-color";
          });
          this.setState({
            tableData: CommonService.addOrdinalColumn(parsedResponse),
            primaryTableData: CommonService.addOrdinalColumn(parsedResponse),
            paintTable: true,
            isLoadingData: false
          }, () => {
            this.setState({ paintTable: false });
          });
        })
        .catch(notOKResponse => {
          this.setState({
            isLoadingData: false,
            tableData: [],
            primaryTableData: [],
            paintTable: true
          }, () => {
            this.setState({ paintTable: false });
          });
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ActiveDirectoryUser/GetUsers", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onLookupClick(filterValue) {
    try {
      this.setState({
        showSpinner: true,
        paintTable: true
      }, () => {
        this.setState({ paintTable: false });
      });

      let filterRegex = new RegExp(filterValue, "i");
      let tableData = this.state.primaryTableData.filter(rowData => rowData.userPrincipalName?.search(filterRegex) !== -1
        || (rowData.displayName ? rowData.displayName.search(filterRegex) !== -1 : false)
        || (rowData.mail ? rowData.mail.search(filterRegex) !== -1 : false)
        || (rowData.otherMailsTblCol ? rowData.mail.search(filterRegex) !== -1 : false)
        || (rowData.proxyAddressesTblCol ? rowData.mail.search(filterRegex) !== -1 : false))

      this.setState({
        showSpinner: false,
        tableData: tableData,
        paintTable: true,
      }, () => {
        this.setState({ paintTable: false });
      });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onCopyTableCallBack(event) {
    try {
      this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
      CommonService.clientAzureStorageLog("ActiveDirectoryUserRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  render() {
    return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
      <div>
        <span className="float-right badge badge-primary dgdg-badge dgdg-icon">
          Mailbox Last Updated Date: {this.state.mailboxLastUpdatedDate ? CommonService.formatDateTimeZone(this.state.mailboxLastUpdatedDate) : ""}
        </span>
        <span className="float-right badge badge-primary dgdg-badge">
          Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
        </span>
      </div>
      <div className="dgdg-flex-item dgdg-site-content dgdg-activedirectory-user-content">
        <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
          <ActiveDirectoryUserQueryComponent
            applicationInsights={this.props.applicationInsights}
            isLoadingData={this.state.isLoadingData}
            onLookupClick={this.onLookupClick}
            onSearchClick={this.onSearchClick}
          />
          <div className="dgdg-site-scrollable-content">
            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Active Directory Users" tableData={this.state.tableData} paintTable={this.state.paintTable}
              tablePageSize={CommonService.tablePageSize} stickyHeader
              copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19], onCopyTableCallBack: this.onCopyTableCallBack }}
            >
              <DGDGTableHeaderComponent position="PreHeader">
                <tr className="dgdg-table-v3-header">
                  <td />
                  <td colSpan="10" className="text-center font-weight-bol dgdg-advisor-bg-head dgdg-activedirectory-user-activedirectory-color">Active Directory</td>
                  <td colSpan="9" className="text-center font-weight-bol dgdg-advisor-bg-head dgdg-activedirectory-user-palocity-color">Paylocity</td>
                </tr>
              </DGDGTableHeaderComponent>
              <DGDGTableRowComponent>
                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="dgdg-directory-bg-white" />
                <DGDGTableColumnComponent headerText="User Principal Name" dataColumn="userPrincipalName" sortColumn="userPrincipalName" filterColumn="userPrincipalName" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Display Name" dataColumn="displayName" sortColumn="displayName" filterColumn="displayName" cssName="dgdg-activedirectory-user-activedirectory-color" />
                <DGDGTableColumnComponent headerText="Mail" dataColumn="mail" sortColumn="mail" filterColumn="mail" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Other Mails" dataColumn="otherMailsTblCol" sortColumn="otherMailsTblCol" filterColumn="otherMailsTblCol" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-logon-email-width" />
                <DGDGTableColumnComponent headerText="Proxy Addresses" dataColumn="proxyAddressesTblCol" sortColumn="proxyAddressesTblCol" filterColumn="proxyAddressesTblCol" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Business Phones" dataColumn="businessPhones" sortColumn="businessPhones" filterColumn="businessPhones" dataType="phone" cssName="dgdg-activedirectory-user-activedirectory-color text-nowrap" />
                <DGDGTableColumnComponent headerText="Licenses" dataColumn="licenses" sortColumn="licenses" filterColumn="licenses" cssName="dgdg-activedirectory-user-activedirectory-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Mailbox Size" dataColumn="mailboxSize" sortColumn="mailboxSize" filterColumn="mailboxSize" cssName="dgdg-activedirectory-user-activedirectory-color text-nowrap" />
                <DGDGTableColumnComponent headerText="Created Date" dataColumn="createdDate" sortColumn="createdDate" filterColumn="createdDate" dataType="date" cssName="dgdg-activedirectory-user-activedirectory-color" />
                <DGDGTableColumnComponent headerText="Account Enabled" dataColumn="accountEnabled" sortColumn="accountEnabled" filterColumn="accountEnabled" dataType="bool" />
                <DGDGTableColumnComponent headerText="Store Number" dataColumn="storeNumber" sortColumn="storeNumber" filterColumn="storeNumber" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Work Email" dataColumn="workEmail" sortColumn="workEmail" filterColumn="workEmail" cssName="dgdg-activedirectory-user-palocity-color dgdg-activedirectory-user-principal-name-width" />
                <DGDGTableColumnComponent headerText="Status" dataColumn="paylocityStatus" sortColumn="paylocityStatus" filterColumn="paylocityStatus" />
                <DGDGTableColumnComponent headerText="Start Date" dataColumn="hireDate" sortColumn="hireDate" filterColumn="hireDate" dataType="date" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Term Date" dataColumn="termDate" sortColumn="termDate" filterColumn="termDate" dataType="date" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Work Phone" dataColumn="workPhone" sortColumn="workPhone" filterColumn="workPhone" dataType="phone" cssName="dgdg-activedirectory-user-palocity-color text-nowrap" />
                <DGDGTableColumnComponent headerText="Job Title" dataColumn="jobTitle" sortColumn="jobTitle" filterColumn="jobTitle" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Employee Number" dataColumn="employeeNumber" sortColumn="employeeNumber" filterColumn="employeeNumber" cssName="dgdg-activedirectory-user-palocity-color" />
                <DGDGTableColumnComponent headerText="Logon Email" dataColumn="logonEmail" sortColumn="logonEmail" filterColumn="logonEmail" cssName="dgdg-activedirectory-user-palocity-color dgdg-activedirectory-logon-email-width" />
              </DGDGTableRowComponent>
            </DGDGTableV3Component>
          </div>
        </div>
      </div>
    </DGDGPageComponent>;
  }
}
